import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3";

import {
  getTestPlan,
  setTestOverviewDetailsEMI,
} from "../../../../redux/actions/testplan-action-emi";
import {
  downloadAction,
  resetDownload,
} from "../../../../redux/actions/download-action";
import { parseS3Link } from "../../../../utils/s3-utils";
import constants from "../../../../constants";

const TestPlanEMI = ({ formMetadata }) => {
  const preFormDataExists =
    Object.keys(formMetadata || {}).length > 0 && Boolean(formMetadata.buildId);

  if (!preFormDataExists) return null;

  const dispatch = useDispatch();
  const [showDownload, setShowDownload] = useState(false);

  const {
    downloadReducer: { loadingStatus: downloadLoadingStatus, url: downloadUrl },
    testplanReducerEMI: { loadingStatus, testPlan, message },
  } = useSelector((state: any) => state);

  const testOverviewDetails = {
    categoryId: formMetadata.categoryId,
    projectId: formMetadata.projectId,
    buildVersionId: formMetadata.buildId,
  };

  useEffect(() => {
    dispatch(setTestOverviewDetailsEMI(testOverviewDetails));
  }, [dispatch]);

  const handleNavigation = () => {
    setShowDownload(true);
  };

  const handleDownloadTestplan = () => {
    const { categoryId, projectId, buildId } = formMetadata;
    dispatch(getTestPlan(categoryId, projectId, buildId));
  };

  const loadedTestPlan = loadingStatus === constants.LOADING_SUCCESS;
  useEffect(() => {
    if (!loadedTestPlan || !testPlan?.s3_link) return;

    const { file_path, object_key, bucket_name, version_id } = parseS3Link(
      testPlan.s3_link
    );
    const data = { file_name: file_path, object_key, bucket_name, version_id };
    dispatch(downloadAction(data));
  }, [dispatch, loadingStatus, testPlan]);

  useEffect(() => {
    const downloadSuccess = downloadLoadingStatus === constants.LOADING_SUCCESS;
    if (!loadedTestPlan || !downloadSuccess || !downloadUrl) return;

    window.open(downloadUrl, "_blank", "noopener,noreferrer");
    dispatch(resetDownload());
  }, [dispatch, loadingStatus, downloadLoadingStatus, downloadUrl]);

  const queryString = new URLSearchParams(testOverviewDetails).toString();
  const testPlanEmiPageUrl = `/testplan/emi/create?${queryString}`;
  const loadingDownloadButton =
    loadingStatus === constants.LOADING_LOAD ||
    downloadLoadingStatus === constants.LOADING_LOAD;
  const downloadFailed =
    loadingStatus === constants.LOADING_FAIL ||
    downloadLoadingStatus === constants.LOADING_FAIL;

  return (
    <Container header={<Header variant="h2">EMI Test Plan</Header>}>
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          variant="primary"
          iconAlign="right"
          iconName="external"
          onClick={handleNavigation}
          href={testPlanEmiPageUrl}
          target="_blank"
        >
          Go To Page
        </Button>

        {showDownload && (
          <>
            <Button
              variant="primary"
              iconName="download"
              onClick={handleDownloadTestplan}
              disabled={loadingDownloadButton}
              loading={loadingDownloadButton}
            >
              Download Testplan
            </Button>

            {downloadFailed && (
              <StatusIndicator type="error">
                {`Error while downloading Testplan${
                  message ? `: ${message}` : ""
                }`}
              </StatusIndicator>
            )}
          </>
        )}
      </SpaceBetween>
    </Container>
  );
};

export default TestPlanEMI;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react-v3";

import constants from "../../../../constants";

const columnDefinitions = [
  {
    id: "technology",
    header: "Technology",
    cell: (item) => item.technologyOption?.label,
    sortingField: "technology",
  },
  {
    id: "specifications",
    header: "Specifications",
    cell: (item) => item.specificationOption?.label,
    sortingField: "specifications",
  },
  {
    id: "antenna",
    header: "Antenna",
    cell: (item) => item.antennaOption?.label,
    sortingField: "antenna",
  },
  {
    id: "minPower",
    header: "Min Power",
    cell: (item) => item.minPower,
    sortingField: "minPower",
  },
  {
    id: "internalMargin",
    header: "Internal Margin",
    cell: (item) => item.internalMargin,
    sortingField: "internalMargin",
  }
];

const TestPlanTable = ({
  formData,
  onFormDataEntry,
  onSubmit,
  submitStatus,
  setShowDownload,
}) => {
  const [tableItems, setTableItems] = useState<any>([]);
  const [selectedTableRows, setSelectedTableRows] = useState<any>([]);

  useEffect(() => {
    if (!Object.keys(formData || {}).length) return;

    const {
      selectedSpecifications,
      selectedTechnology,
      selectedAntenna,
      minPower,
      internalMargin
    } = formData;

    const isDuplicate = selectedSpecifications.some((selectedSpecification) =>
      tableItems.some(
        (item) =>
          item.technologyOption?.value === selectedTechnology?.value &&
          item.specificationOption?.value === selectedSpecification?.value &&
          item.antennaOption?.value === selectedAntenna?.value
      )
    );
    onFormDataEntry({ isDuplicate });

    if (isDuplicate) return;

    const newItems = selectedSpecifications.map(
      (specificationOption, index) => ({
        id: `${Date.now()}-${index}`,
        technologyOption: selectedTechnology,
        specificationOption,
        antennaOption: selectedAntenna,
        minPower,
        internalMargin
      })
    );

    setTableItems((prev) => [...prev, ...newItems]);
  }, [formData]);

  const deleteSelectedRows = () => {
    setTableItems((prev) =>
      prev.filter(
        (entry) =>
          !selectedTableRows.some((selectedRow) => selectedRow.id === entry.id)
      )
    );
    setSelectedTableRows([]);
  };

  const clearTable = () => {
    setTableItems([]);
    setSelectedTableRows([]);
  };

  useEffect(() => setShowDownload(false), [tableItems]);

  const tablePopulated = tableItems.length > 0;

  const handleSubmitClick = () => {
    if (!tablePopulated) return;

    onSubmit(tableItems);
  };

  return (
    <>
      <Table
        variant="embedded"
        columnDefinitions={columnDefinitions}
        items={tableItems}
        selectedItems={selectedTableRows}
        onSelectionChange={({ detail }) =>
          setSelectedTableRows(detail.selectedItems)
        }
        selectionType="multi"
        trackBy="id"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box variant="p" color="inherit">
              No resources to display.
            </Box>
          </Box>
        }
        header={
          <Header
            variant="h3"
            actions={
              <Button
                iconName="close"
                variant="primary"
                onClick={deleteSelectedRows}
                disabled={selectedTableRows.length === 0}
              >
                Delete
              </Button>
            }
          />
        }
      />

      <br />
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          variant="normal"
          onClick={clearTable}
          disabled={!tablePopulated}
        >
          Clear
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmitClick}
          disabled={!tablePopulated}
          loading={submitStatus === constants.LOADING_LOAD}
        >
          Submit Testplan
        </Button>
      </SpaceBetween>
    </>
  );
};

export default TestPlanTable;

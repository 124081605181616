class Endpoints {
  /* 
          define all you endpoints here
          each endpoint will be in form of function 
      */
  sampleEndpoint = (if_you_need_variable: string) =>
    `/this_is_an_example/${if_you_need_variable}`;

  /* ***************************************************************** */

  /* Upload related */
  // file name check
  uploadFileNameCheck = () => "/upload/checkfile";

  // generate pre-signed url
  uploadPreSignedURL = () => "/upload";

  // upload status
  uploadStatus = () => "/upload/status";

  /* ***************************************************************** */

  // authz
  authzEndpoint = () => "/authz";

  // metadata
  metadataEndpoint = () => "/metadata";

  // upload
  uploadEndpoint = () => "/upload_logs";

  // get upload

  getUploadEndpoint = (start_date, end_date) =>
    `/upload_logs?start_date=${start_date}&end_date=${end_date}`;

  // trigger auto upload
  autoUploadEndpoint = () => "/auto_upload";

  // landing/dashboard table
  landingEndpoint = () => "/summary";

  // User metrics
  metricEndpoint = () => `/log/metrics`;
  // category endpoint
  categoryEndpoint = () => `/categories`;

  // project endpoint
  projectEndpoint = (category_id) => `/categories/${category_id}/projects`;

  // build endpoint
  buildEndpoint = (categoryId: string, projectId: string) =>
    `/categories/${categoryId}/projects/${projectId}/builds`;

  updateBuildStatusEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_status`;

  //testplan endpoint
  testplanEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string,
    buildConfigId: number
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_configs/${buildConfigId}/test_plans`;

  //testplandraft endpoint
  testplanDraftEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string,
    buildConfigId: number
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_configs/${buildConfigId}/test_plans/draft`;

  //build config endpoint
  buildConfigEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_configs`;

  buildEmiEndpoint = (categoryId: string, projectId: string, buildId: string) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_page/emi`;

  // testplan config endpoint
  testplanConfigEndpoint = () => `/rf_spec`;

  // get testcases base on rfrule
  rfRuleEndpoint = (
    country_id: string,
    technology_id: number,
    tech_spec_id: number
  ) =>
    `/rf_spec/rules?country_id=${country_id}&technology_id=${technology_id}&tech_spec_id=${tech_spec_id}`;

  // latest testplan endpoint
  latestTestplanEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string,
    buildConfigId: number
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_configs/${buildConfigId}/test_plans/latest`;

  // latest testplan endpoint
  sendMailTestplanEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string,
    buildConfigId: number
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_configs/${buildConfigId}/test_plans/notify`;

  // Overview testcases endpoint
  testcasesForAllRegions = (
    categoryId: string,
    projectId: string,
    buildId: string,
    buildConfigId: number
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_configs/${buildConfigId}/test_cases`;

  // Update testcases endpoint
  updateTestcasesEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string,
    buildConfigId: number
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_configs/${buildConfigId}/test_cases/update`;

  // get testcase raw data endpoint
  getTestcaseRawDataEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string,
    buildConfigId: number,
    test_case_id: number,
    power_setting: string,
    data_type: string
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/build_configs/${buildConfigId}/test_cases/${test_case_id}/raw_data/power_settings/${power_setting}/data_types/${data_type}`;

  testcaseSummaryEndpoint = (test_case_id: number) =>
    `/test_case_summary/${test_case_id}`;

  // Report generation
  reportEndpoint = () => `/reports`;

  // Email verification
  emailVerificationEndpoint = () => `/email/verify`;

  // Report history
  reportHistoryEndpoint = () => `/reports/history`;

  getTestPlan(category: string, project: string, build: string) {
    return `/categories/${category}/projects/${project}/builds/${build}/test_plans/emi`;
  }

  createTestPlan(category: string, project: string, build: string) {
    return `/categories/${category}/projects/${project}/builds/${build}/test_plans/create`;
  }

  getTestPlanDraft(
    category: string,
    project: string,
    build: string,
    version: number
  ) {
    return `/modules/compliance/categories/${category}/projects/${project}/builds/${build}/versions/${version}/testPlan/draft/latest`;
  }

  getTestPlans(category: string, project: string, build: string) {
    return `/categories/${category}/projects/${project}/builds/${build}/test_plans/history`;
  }

  //get emi testing data
  getEmiTestingData(
    categoryId: string,
    projectId: string,
    buildId: string,
    id: string,
    points: string
  ) {
    return `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/ids/${id}/points/${points}

    `;
  }

  testcaseDetailEndpoint = (
    categoryId: string,
    projectId: string,
    buildId: string,
    id: number
  ) =>
    `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/ids/${id}/details`;

  getTestPlanEmiDraft(categoryId: string, projectId: string, buildId: string) {
    return `/categories/${categoryId}/projects/${projectId}/builds/${buildId}/test_plan/draft/latest`;
  }

  // download
  downloadEndpoint = () => "/download";

  createTestPlanDraftEMIEndpoint(
    category: string,
    project: string,
    build: string,
    version: number
  ) {
    return `/categories/${category}/projects/${project}/builds/${build}/test_plan/draft`;
  }

  getSpecificTestCaseData(testCategory, testcase) {
    return `/modules/compliance/testcategories/${testCategory}/testcases/id/${testcase}`;
  }

  getuploadExcelFile() {
    return `/upload/filenamecheck`;
  }

  uploadExcelFile() {
    return `/upload/excel`;
  }

  jiraUsersUrl() {
    return `/jira/projects/developers`;
  }

  // ace endpoints
  extractSectionStructure = () => `/extract_sections`;
  dataSourceParsing = () => `/ace/data_source`;
  appendTextToAce = () => `/ace/text`;
  createAceRecord = () => `/ace/record`;
  jiraAutomation = () => `/ace/jira`;
  addLimitsSection = () => `/ace/limits/radiated_testing`;
  addStandardsSection = () => `/ace/standards/radiated_testing`;
  downloadAceDocument = () => `/ace/download/`;
}

const endpoints = new Endpoints();
export default endpoints;

import { put, takeEvery, call } from "redux-saga/effects";
import { redirectToLoginPage, getAccessToken } from "../cognito-auth/session";
import constants from "../constants";

//Watcher: liston to specific action and call worker to take actions
// watch upload link action
export function* watchHttpAction() {
  yield takeEvery("*", sendHttpRequest);
}

//Worker
export function* sendHttpRequest(action) {
  if ("http" in action && "cognito" in action.http) {
    yield cognitoRequestSender(action);
  }
}
// send http request to lambda with cognito authorizer
function* cognitoRequestSender(action) {
  const { http, onResponse } = action;
  const { effect, commit, rollback } = http.cognito;
  const token = yield getAccessToken();
  // verify if the session is still valid
  if (token !== "") {
    try {
      const response = yield call(fetch, effect.url, {
        method: effect.method,
        headers:
          "headers" in effect
            ? {
                Authorization: token,
                ...effect.headers,
              }
            : {
                Authorization: token,
              },
        body: "json" in effect ? JSON.stringify(deepTrim(effect.json)) : null,
      });
      
      const statusCode = response.status;
      const jsonResponse = yield response.text();
      const payload = response.ok
        ? JSON.parse(jsonResponse.replace(/\bNaN\b/g, "null"))
        : JSON.parse(jsonResponse);
      const actionToDispatch = response.ok ? commit : rollback;

      yield put({ ...actionToDispatch, statusCode, payload });

      if (onResponse && typeof onResponse === "function") {
        const loadingStatus = response.ok
          ? constants.LOADING_SUCCESS
          : constants.LOADING_FAIL;

        onResponse({ loadingStatus, data: payload });
      }
    } catch (e) {
      console.log(e);
      yield put(rollback);
    }
  } else {
    // cognito login
    console.log("session expired!");
    redirectToLoginPage();
  }
}

function deepTrim(obj) {
  for (var prop in obj) {
    var value = obj[prop],
      type = typeof value;
    if (
      value != null &&
      (type == "string" || type == "object") &&
      obj.hasOwnProperty(prop)
    ) {
      if (type == "object") {
        deepTrim(obj[prop]);
      } else {
        obj[prop] = obj[prop].trim();
      }
    }
  }
  return obj;
}
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Form,
  Button,
  Alert,
  Header,
  SpaceBetween,
  Container,
  StatusIndicator,
  SelectProps,
  FormField,
  Checkbox,
  Input,
  Box,
  Link,
} from "@amzn/awsui-components-react-v3/polaris";

import {
  appendTextToAce,
  dataSourceParsing,
  createAceRecord,
  extractSectionStructure,
} from "../../../redux/actions/ace-action";
import {
  AceTechnology,
  BRD_TECHNOLOGY_LIST,
  AceDocRowHeaders,
  COUNTRY_LIST,
  CountryCode,
} from "../../../utils/constants";
import UploadFileDropzone from "../testplan-emi/manual-upload-module/file-dropzone";
import {FileStatus} from "../../../model/upload";
import constants, {Status, TEXT_TYPE} from "../../../constants";
import UploadingModal from "../testplan-emi/manual-upload-module/uploading-model";
import {fileUploadS3Call} from "../../upload/manual-upload-module/utils";
import {getConfluencePageDetails, getSectionStatusMap, SectionProgressT} from "./ace-utils";
import AceJiraAutomation from "./jira-automation";
import AceRadiatedTestingLimits from "./radiated-testing-limits";
import AceRadiatedTestingStandards from "./radiated-testing-standards";
import AceTestPlan from "./test-plan/test-plan";
import {validateConfluenceUrl} from "../../../utils/validation-utils";
import {AceSectionSelector} from "./ace-section-selector";

type ValidationState = {
  isValid: boolean;
  aceDocumentNameMessage?: string;
  confluenceParentUrlMessage?: string;
}

function AceCreate() {
  const description = "Upload ACE docs";
  const hasError: boolean = false;
  const dispatch = useDispatch();
  const {
    extractSectionsRequest,
    extractSectionsLoadingStatus,
    extractSectionsResponse,
    createAceRecordLoadingStatus,
    createAceRecordResponse
  } = useSelector((state: any) => state.aceReducer);

  const [aceDocumentName, setAceDocumentName] = useState<string>("");
  const [confluenceParentUrl, setConfluenceParentUrl] = useState<string>("");
  const [disableAceIdentifiers, setDisableAceIdentifiers] = useState<boolean>(false);
  const [aceId, setAceId] = useState<string>("");
  const [confluenceUrl, setConfluenceUrl] = useState<string>("");
  const [validationState, setValidationState] = React.useState<ValidationState>({
    isValid: true,
  });

  const [filesUploadStatus, setFilesUploadStatus] = useState<number>(
    constants.LOADING_DEFAULT
  );

  const [dataSourceResponse, setDataSourceResponse] = useState<{
    loadingStatus: number;
    data?: any;
  }>({loadingStatus: constants.LOADING_DEFAULT});
  const [uploadedFileOptions, setUploadedFileOptions] =
    useState<SelectProps.Options>([]);
  const [selectedFileMap, setSelectedFileMap] = useState<{
    [header: string]: string | null;
  }>({});
  const [sectionStructureMap, setSectionStructureMap] = useState<{
    [fileName: string]: SelectProps.Options;
  }>({});
  const [selectedSectionOptionsMap, setSelectedSectionOptionsMap] = useState<{
    [header: string]: { [fileName: string]: SelectProps.Options };
  }>({});
  const [selectedPageNumbersMap, setSelectedPageNumbersMap] = useState<{
    [header: string]: { [fileName: string]: SelectProps.Options };
  }>({});
  const [sectionProgress, setSectionProgress] = useState<SectionProgressT>({});

  const sectionStatusMap = useMemo(
    () => getSectionStatusMap(sectionProgress),
    [sectionProgress]
  );

  const [selectedTechnologies, setSelectedTechnologies] =
    useState(BRD_TECHNOLOGY_LIST);
  const selectAllTechnologies =
    selectedTechnologies.length === BRD_TECHNOLOGY_LIST.length;
  const [selectedCountries, setSelectedCountries] = useState(COUNTRY_LIST);
  const selectAllCountries = selectedCountries.length === COUNTRY_LIST.length;

  const [fileData, setFileData] = useState<{
    fileList: Array<File>;
    fileCheckDict: {
      [key: string]: FileStatus;
    };
    fileUploadDict: {
      [key: string]: FileStatus;
    };
  }>({fileList: [], fileCheckDict: {}, fileUploadDict: {}});

  const [showModal, setShowModal] = useState<boolean>(false);
  const {fileList, fileCheckDict, fileUploadDict} = fileData;
  const _deleteFile = (filename: string) => {
    if (filename in fileCheckDict) {
      delete fileCheckDict[filename];
    }

    if (filename in fileUploadDict) {
      delete fileUploadDict[filename];
    }

    const fileListName = fileList.filter((file: File) => {
      return file.name !== filename;
    });
    setFileData({
      fileList: fileListName,
      fileCheckDict: fileCheckDict,
      fileUploadDict: fileUploadDict,
    });
  };

  // event: when on drag and drop file in dropzone
  const _onChangeFiles = (files: Array<File>) => {
    const newFileList: Array<File> = [];
    const newFileCheckDict: { [key: string]: FileStatus } = {};
    const newFileUploadDict: { [key: string]: FileStatus } = {};

    files.forEach((file: File) => {
      // repeated file
      if (!(file.name in fileCheckDict)) {
        newFileList.push(file);
        newFileCheckDict[file.name] = {
          status: constants.LOADING_SUCCESS,
          message: "",
          s3_link: "",
        };
        newFileUploadDict[file.name] = {
          status: constants.LOADING_SUCCESS,
          message: "",
          s3_link: "",
        };
      }
    });

    setFileData({
      fileList: [...fileList, ...newFileList],
      fileCheckDict: {...fileCheckDict, ...newFileCheckDict},
      fileUploadDict: {...fileUploadDict, ...newFileUploadDict},
    });
  };

  // onClick clear button
  const _onClear = () => {
    setFileData({
      fileList: [],
      fileCheckDict: {},
      fileUploadDict: {},
    });
    setShowModal(false);
  };

  const _closeModal = () => {
    // clear form
    _onClear();
  };

  /* 
    check if need to disable upload button
    case 1: fileCheckList is empty
    case 2: Any file name is checking or failed
  */
  const _checkDisableUploadButton = () => {
    // case 1:
    if (fileList.length === 0) {
      return true;
    }

    // case 2:
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (
        fileCheckDict[filename] &&
        fileCheckDict[filename].status !== constants.LOADING_SUCCESS
      ) {
        return true;
      }
    }

    // otherwise, do not disable, return false
    return false;
  };

  /* 
    check file name validation in fileCheckList
    return false if any of the file name is invalid
    return true if all file names are valid
  */
  const _validateForm = () => {
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (
        fileCheckDict[filename] &&
        fileCheckDict[filename].status === constants.LOADING_FAIL
      ) {
        return false;
      }
    }
    return true;
  };
  const _showModal = () => {
    setShowModal(true);
  };

  const _uploadFiles = async () => {
    setFilesUploadStatus(constants.LOADING_LOAD);

    const uploadPromises = fileList.map(async (file) => {
      const rst = await fileUploadS3Call(file, constants.INITIATIVE_ACE);

      setFileData((prev) => ({
        ...prev,
        fileUploadDict: {...prev.fileUploadDict, [file.name]: rst},
      }));

      setUploadedFileOptions((prev) => [
        ...prev,
        {label: file.name, value: file.name},
      ]);
    });

    await Promise.all(uploadPromises);

    setFilesUploadStatus(constants.LOADING_SUCCESS);
  };
  /*
    onClick Upload button
    1. fileList is empty => alert
    2. validate file name failed => alert
    3. all good => confirm
        3.1 no => clear and return
        3.2 yes => open model, start upload process
  */
  const _onClickUpload = () => {
    // 1. fileList is empty
    if (fileList.length === 0) {
      alert("No file was uploaded.");
      return;
    }

    // 2. validate file name failed
    if (!_validateForm()) {
      alert("Invalid file name.");
      return;
    }

    // 3. all good, confirm
    let isConfirmed = window.confirm(
      "Files will be uploaded to LENS. Click OK to confirm. "
    );

    if (!isConfirmed) {
      return;
    }
    _showModal();
    _uploadFiles();
  };

  const handleFileSelect = (header: string, selectedOption: any) => {
    const fileName = selectedOption.detail.selectedOption.value;
    setSelectedFileMap((prev) => ({...prev, [header]: fileName}));

    // Reset the selected sections for the header if the document changes
    setSelectedSectionOptionsMap((prev) => ({
      ...prev,
      [header]: {[fileName]: []},
    }));

    // Extract section of the file if data is not already fetched
    if (!sectionStructureMap[fileName]) {
      dispatch(extractSectionStructure({file_name: fileName}));
    }
  };

  const handlePageSelect = (pageNumbers, sectionId, selectedFile) => {
    setSelectedPageNumbersMap(previousMap => {
      const newMap = {...previousMap};
      if (!newMap[sectionId]) {
        newMap[sectionId] = {};
      }
      newMap[sectionId][selectedFile] = pageNumbers;
      return newMap;
    });
  };


  // Update sections data whenever new file is selected
  useEffect(() => {
    const fileName = extractSectionsRequest?.file_name;
    const sectionStructure = extractSectionsResponse?.data?.["extracted_sections"] || [];
    const sectionNames = sectionStructure?.["section_names"] || [];
    const totalPages = sectionStructure?.["total_pages"] || undefined;

    const sectionOptions = sectionNames.map((section: string) => ({
      label: section,
      value: section,
    }));
    if (fileName && sectionNames) {
      setSectionStructureMap((prev) => ({
        ...prev,
        [fileName]: {
          sectionOptions: sectionOptions,
          totalPages: totalPages
        }
      }));
    }
  }, [extractSectionsRequest, extractSectionsResponse]);

  const handleSectionsSelect = (
    header: string,
    fileName: string,
    event: any
  ) => {
    setSelectedSectionOptionsMap((prev) => ({
      ...prev,
      [header]: {
        ...prev[header],
        [fileName]: event.detail.selectedOptions,
      },
    }));
  };

  const handleTechnologiesChange = (technology: AceTechnology) => {
    setSelectedTechnologies((prevSelected) => {
      if (prevSelected.includes(technology)) {
        return prevSelected.filter((item) => item !== technology);
      } else {
        return [...prevSelected, technology];
      }
    });
  };

  const handleSelectAllTechnologies = () => {
    setSelectedTechnologies(!selectAllTechnologies ? BRD_TECHNOLOGY_LIST : []);
  };

  const handleCountriesChange = (country: CountryCode) => {
    setSelectedCountries((prevSelected) => {
      if (prevSelected.includes(country)) {
        return prevSelected.filter((item) => item !== country);
      } else {
        return [...prevSelected, country];
      }
    });
  };

  const handleSelectAllCountries = () => {
    setSelectedCountries(!selectAllCountries ? COUNTRY_LIST : []);
  };

  const validateInputs = () => {
    let isValid = true;
    const validationState: ValidationState = {
      isValid: true
    };
    if (!aceDocumentName) {
      validationState["aceDocumentNameMessage"] = "Ace document name is invalid. It cannot be empty"
      isValid = false;
    }
    if (!confluenceParentUrl || confluenceParentUrl.includes(" ")) {
      validationState["confluenceParentUrlMessage"] = "Confluence parent url is invalid. It cannot be empty and" +
        " cannot contain spaces"
      isValid = false;
    }
    if (!validateConfluenceUrl(confluenceParentUrl)) {
      validationState["confluenceParentUrlMessage"] = "Confluence parent url is invalid. Please make sure it is an" +
        " existing confluence page url"
      isValid = false;
    }
    setValidationState(validationState);
    return isValid;
  }

  const updateSectionsStatus = (
    header: string,
    fileName: string,
    sections: (string | undefined)[],
    status: Status
  ) => {
    const updatedSections = {};
    for (const section of sections) {
      if (section) updatedSections[section] = status;
    }

    setSectionProgress((prev) => ({
      ...prev,
      [header]: {
        ...prev[header],
        [fileName]: {...prev[header]?.[fileName], ...updatedSections},
      },
    }));
  };

  const initializePendingSectionsStatus = () => {
    Object.entries(selectedSectionOptionsMap).forEach(
      ([header, fileSections]) => {
        Object.entries(fileSections).forEach(
          ([fileName, selectedSectionOptions]) => {
            const sections = selectedSectionOptions.map(({value}) => value);
            let pages: any = selectedPageNumbersMap?.[header]?.[fileName] || [];
            pages = pages.map(({value}) => value);
            const allSections = [...sections, ...pages]
            updateSectionsStatus(header, fileName, allSections, Status.Pending);
          }
        );
      }
    );
  };

  useEffect(() => {
    if (createAceRecordLoadingStatus === constants.LOADING_SUCCESS && createAceRecordResponse?.ace_id) {
      setAceId(createAceRecordResponse.ace_id);
      setConfluenceUrl(createAceRecordResponse.confluence_url);
    }
  }, [createAceRecordResponse])

  useEffect(() => {
    if (aceId) {
      addSectionsDataToAce();
    }
  }, [aceId]);

  const handleSubmitSections = () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (createAceRecordLoadingStatus !== constants.LOADING_SUCCESS) {
      dispatch(createAceRecord(aceDocumentName, selectedCountries, selectedTechnologies, confluenceParentUrl))
    }
  }

  const addSectionsDataToAce = async () => {
    let response = {loadingStatus: constants.LOADING_LOAD};
    setDataSourceResponse(response);
    initializePendingSectionsStatus();

    for (const [header, fileSections] of Object.entries(
      selectedSectionOptionsMap
    )) {
      for (const [fileName, selectedSectionOptions] of Object.entries(
        fileSections
      )) {
        let pages: any = selectedPageNumbersMap?.[header]?.[fileName] || [];
        if (fileName && (selectedSectionOptions?.length || pages.length)) {
          const sections = selectedSectionOptions.map(({value}) => value);
          pages = pages.map(({value}) => value);
          const allSections = [...sections, ...pages]

          updateSectionsStatus(header, fileName, allSections, Status.InProgress);
          try {
            // Wrap the dispatch in a Promise to wait for response callback
            const appendResponse = await new Promise<{ loadingStatus: number }>(
              (resolve) => {
                const requestBody = {
                  ace_id: aceId,
                  text_to_add: header,
                  text_type: TEXT_TYPE.Header1,
                };
                dispatch(appendTextToAce(requestBody, resolve));
              }
            );

            if (appendResponse.loadingStatus === constants.LOADING_FAIL) {
              setDataSourceResponse(appendResponse);
              updateSectionsStatus(header, fileName, allSections, Status.Failed);
              return;
            }

            const dataSourceResponse = await new Promise<{ loadingStatus: number }>(
              (resolve) => {
                const requestBody = {
                  ace_id: aceId,
                  doc_name: fileName,
                  section_names: allSections,
                };
                dispatch(dataSourceParsing(requestBody, resolve));
              }
            );

            if (dataSourceResponse.loadingStatus === constants.LOADING_FAIL) {
              setDataSourceResponse(dataSourceResponse);
              updateSectionsStatus(header, fileName, allSections, Status.Failed);
              return;
            } else {
              response = dataSourceResponse;
              updateSectionsStatus(header, fileName, allSections, Status.Success);
            }
          } catch (error) {
            console.error(error);
            updateSectionsStatus(header, fileName, allSections, Status.Failed);
          }
        }
      }
    }
    setDataSourceResponse(response);
  };

  useEffect(() => {
    // Freezing document name once a backend call succeeds because it is being used as ace identifier
    // So it should not change
    if (
      dataSourceResponse.loadingStatus == constants.LOADING_SUCCESS &&
      !disableAceIdentifiers
    ) {
      setDisableAceIdentifiers(true);
    }
  }, [dataSourceResponse, disableAceIdentifiers]);

  const loadingSections =
    extractSectionsLoadingStatus === constants.LOADING_LOAD;

  let isSectionsSelected = Object.values(selectedSectionOptionsMap).some(
    (fileSections) =>
      Object.values(fileSections).some(
        (selectedSectionOptions) => selectedSectionOptions?.length > 0
      )
  );
  const isPagesSelected = Object.values(selectedPageNumbersMap).some(
    (fileSections) =>
      Object.values(fileSections).some(
        (selectedPageNumbers) => selectedPageNumbers?.length > 0
      )
  );

  const loadingDataSourceParse = dataSourceResponse.loadingStatus === constants.LOADING_LOAD;

  const clearValidationState = () => {
    setValidationState({
      isValid: true,
      aceDocumentNameMessage: "",
    })
  }

  const dataSourceResponseError =
    dataSourceResponse.loadingStatus === constants.LOADING_FAIL
      ? dataSourceResponse.data?.data?.error || dataSourceResponse.data?.message
      : null;

  const confluencePageDetails = useMemo(() => {
    if (confluenceParentUrl) {
      return getConfluencePageDetails(confluenceParentUrl);
    }
    return {
      space: '',
      pageName: ''
    };
  }, [confluenceParentUrl]);

  return (
    <Container>
      <div>
        <Form header={<Header variant="h1" description={description}></Header>}>
          {hasError && (
            <React.Fragment>
              <Alert header="Errors Detected" type="error">
                Please check you file name, and try again.
              </Alert>
              <br/>
            </React.Fragment>
          )}

          <UploadFileDropzone
            fileList={fileList}
            fileCheckDict={fileCheckDict}
            onChange={_onChangeFiles}
            deleteFile={_deleteFile}
          />
        </Form>
        <br/>

        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={_onClear}>Clear</Button>
          <Button
            variant="primary"
            onClick={_onClickUpload}
            disabled={_checkDisableUploadButton()}
          >
            Upload
          </Button>
        </SpaceBetween>

        {filesUploadStatus === constants.LOADING_LOAD && (
          <>
            <br/>
            <StatusIndicator type="loading">Uploading files</StatusIndicator>
          </>
        )}
        {(<>
            <br/>
            <Container
              header={
                <Header variant="h2">Document and Section Selection</Header>
              }
            >
              <SpaceBetween size="l">
                {Object.values(AceDocRowHeaders).map((header) => {
                  return (
                    <div key={header}>
                      <Header variant="h3">{header}</Header>
                      <AceSectionSelector
                        sectionId={header}
                        selectedFileMap={selectedFileMap}
                        sectionStructureMap={sectionStructureMap}
                        selectedSectionOptionsMap={selectedSectionOptionsMap}
                        selectedPageNumbersMap={selectedPageNumbersMap}
                        uploadedFileOptions={uploadedFileOptions}
                        handleFileSelect={handleFileSelect}
                        handlePageSelect={handlePageSelect}
                        loadingSections={loadingSections}
                        handleSectionsSelect={handleSectionsSelect}
                      />
                    </div>
                  );
                })}

                <Header variant="h2">
                  Please confirm the list of technologies identified from BRD
                </Header>
                <Checkbox
                  checked={selectAllTechnologies}
                  onChange={handleSelectAllTechnologies}
                >
                  Select All
                </Checkbox>
                <SpaceBetween direction="horizontal" size="m">
                  {BRD_TECHNOLOGY_LIST.map((technology) => (
                    <Checkbox
                      key={technology}
                      checked={selectedTechnologies.includes(technology)}
                      onChange={() => handleTechnologiesChange(technology)}
                    >
                      {technology}
                    </Checkbox>
                  ))}
                </SpaceBetween>

                <Header variant="h2">
                  Please confirm the list of countries identified from BRD
                </Header>
                <Checkbox
                  checked={selectAllCountries}
                  onChange={handleSelectAllCountries}
                >
                  Select All
                </Checkbox>
                <SpaceBetween direction="horizontal" size="m">
                  {COUNTRY_LIST.map((country) => (
                    <Checkbox
                      key={country}
                      checked={selectedCountries.includes(country)}
                      onChange={() => handleCountriesChange(country)}
                    >
                      {country}
                    </Checkbox>
                  ))}
                </SpaceBetween>

                <FormField
                  label="ACE Document Name"
                  description="Please enter an unique name for the ACE document"
                  errorText={validationState?.aceDocumentNameMessage}
                >
                  <Input
                    value={aceDocumentName}
                    placeholder="Enter ACE document name"
                    disabled={disableAceIdentifiers}
                    onChange={event => {
                      clearValidationState();
                      setAceDocumentName(event.detail.value)
                    }}
                  />
                </FormField>
                <FormField
                  label="Confluence parent url"
                  description="Please enter confluence url for parent page of Ace document"
                  errorText={validationState?.confluenceParentUrlMessage}
                >
                  <SpaceBetween
                    size="s"
                    direction="vertical"
                  >
                    <Input
                      value={confluenceParentUrl}
                      placeholder="Enter confluence parent url"
                      disabled={disableAceIdentifiers}
                      onChange={event => {
                        clearValidationState();
                        setConfluenceParentUrl(event.detail.value)
                      }}
                    />
                    <Box
                      color={"text-status-info"}
                    >
                      <b>Confluence space - </b> {confluencePageDetails.space || 'NA'} &nbsp;
                      <b>Page name - </b> {confluencePageDetails.pageName || 'NA'}
                    </Box>
                  </SpaceBetween>
                </FormField>
                <Button
                  variant="primary"
                  onClick={handleSubmitSections}
                  /*
                    Disable submit button when neither sections are selected nor page numbers are selected.
                    Also disable when a submit call is in progress
                  */
                  disabled={(!isSectionsSelected && !isPagesSelected) || loadingDataSourceParse}
                >
                  Submit
                </Button>
              </SpaceBetween>
              <br/>
              {dataSourceResponse.loadingStatus === constants.LOADING_FAIL && (
                <StatusIndicator type="error">
                  Error in Parsing{dataSourceResponseError ? `: ${dataSourceResponseError}` : ""}
                </StatusIndicator>
              )}
              {![constants.LOADING_SUCCESS, constants.LOADING_FAIL].includes(
                  dataSourceResponse.loadingStatus
                ) &&
                sectionStatusMap[Status.InProgress]?.length > 0 && (
                  <div>
                    <StatusIndicator type="loading">
                      Sections in Progress:
                    </StatusIndicator>
                    <span>
                &nbsp;{sectionStatusMap[Status.InProgress].join(", ")}
              </span>
                  </div>
                )}
              {sectionStatusMap[Status.Failed]?.length > 0 && (
                <div>
                  <StatusIndicator type="warning">
                    Failed at sections:
                  </StatusIndicator>
                  <span>&nbsp;{sectionStatusMap[Status.Failed].join(", ")}</span>
                </div>
              )}
              {sectionStatusMap[Status.Success]?.length > 0 && (
                <div>
                  <StatusIndicator type="info">Sections Added:</StatusIndicator>
                  <span>&nbsp;{sectionStatusMap[Status.Success].join(", ")}</span>
                </div>
              )}
              {sectionStatusMap[Status.Pending]?.length > 0 && (
                <div>
                  <StatusIndicator type="pending">Sections Pending:</StatusIndicator>
                  <span>&nbsp;{sectionStatusMap[Status.Pending].join(", ")}</span>
                </div>
              )}
              {dataSourceResponse.loadingStatus === constants.LOADING_SUCCESS && (
                <>
                  <StatusIndicator type="success">
                    {dataSourceResponse.data?.message}
                  </StatusIndicator>
                </>
              )}
              <br/>
              {createAceRecordLoadingStatus === constants.LOADING_SUCCESS && aceId && <div>
                  <br/>
                  <AceJiraAutomation
                      aceId={aceId}
                  />
                  <br/>
                  <AceRadiatedTestingLimits
                      aceId={aceId}
                      selectedTechnologies={selectedTechnologies}
                  />
                  <br/>
                  <AceRadiatedTestingStandards
                      aceId={aceId}
                  />
                  <br/>
              </div>
              }
              {aceId && (
                <Box margin={{ top: "l" }}>
                  <AceTestPlan
                    selectedTechnologies={selectedTechnologies}
                    selectedCountries={selectedCountries}
                  />
                </Box>
              )}
              {confluenceUrl && <Box margin={{ top: "l" }}>
                  <Link
                      external
                      href={confluenceUrl}
                  >
                      ACE confluence page
                  </Link>
              </Box>
              }
            </Container>
          </>
        )}
      </div>

      <UploadingModal
        showModal={showModal}
        onDismiss={_onClear}
        onClose={_closeModal}
        fileList={fileList}
        fileUploadDict={fileUploadDict}
      />
    </Container>
  );
}

export default AceCreate;

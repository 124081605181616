const constants = {
  INTIATIVE: "compliance",
  INITIATIVE_RF: "complianceRF",
  INITIATIVE_EMI: "complianceEMI",
  INITIATIVE_ACE: "compliancedfc",
  NA: "N/A",
  NULL: "Null",

  SIGNIN: "signIn",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
  SIGNIN_FAILURE: "signIn_failure",
  SIGNOUT: "signOut",

  // These are used for dispatching/listening to custom Hub events.
  SESSION_EXPIRED: "session_expired",
  SESSION_WILL_EXPIRE: "session_will_expire",
  SESSION_TIMER_INTERVAL: 30000,

  // loading status
  LOADING_LOAD: 0,
  LOADING_SUCCESS: 1,
  LOADING_FAIL: -1,
  LOADING_DEFAULT: 10,

  // general status(we can keep all our status values uniformed)
  // file parse status is using this
  STATUS_SUCCESS: "success",
  STATUS_FAIL: "fail",
  STATUS_ERROR: "error", // there are some legacy status using error
  STATUS_PROCESS: "in_process",
  STATUS_NOT_START: "not_start",
  STATUS_PROGRESS: "in_progress",
  STATUS_COMPLETED: "completed",

  FILE_CHECK_SUCCESS: "File check success",
  UPLOAD_CHECK_FILE_SUCCESS: "succeed",
  UPLOAD_CHECK_FILE_FAIL: "failed",

  LANDING_STATUS_PROCESS: "in_process",
  LANDING_STATUS_ARCHIVED: "archived",
  LANDING_STATUS_NOT_START: "not_start",

  TABLE_DEFAULT_MIN_WIDTH: "100px",

  // Initiative of origin
  ORIGIN_INITIATIVE: "compliance",

  // loading status
  LOAD_DATA_LOADING: "loading",
  LOAD_DATA_SUCCESS: "loaded",
  LOAD_DATA_ERROR: "failed",

  EXCEL_INSTRUCTIONS: "Instructions",
  EXCEL_EXTENSION_ERROR:
    "Only Excel Files with extension .xlsm/.xlsx are supported.",
  IN_VALID_DATA_ERROR:
    "Please make sure Excel contains valid data, else parsing may fail.",
};
export default constants;

// test category
export const TEST_CATEGORY = {
  RF: "RF",
  EMI: "EMI",
};

// test status
export const TEST_STATUS = {
  PASSED: "passed",
  WARNING: "warning",
  FAILED: "failed",
  NO_LIMIT: "no_limit",
  UNTESTED: "untested",
};

export const UPLOAD_STATUS = {
  SUCCESS: "success",
  FAILED: "failed",
};

export const COUNTRY = {
  US: "us",
  CA: "ca",
  EU: "eu", // Europe
  MX: "mx", // Mexico
  LK: "lk", // Sri Lanka
  AU: "au", // Australia
  NZ: "nz", // New Zealand
  JP: "jp", // Japan
  IN: "in", // india
  BR: "br", // brazil
  WW: "ww", // World Wide
};

// UI text for status
export const STATUS_TEXT = {
  [constants.STATUS_SUCCESS]: "Success",
  [constants.STATUS_FAIL]: "Fail",
  [constants.STATUS_ERROR]: "Fail",
  [constants.STATUS_PROCESS]: "In Progress",
  [constants.STATUS_NOT_START]: "Not Started",
  [constants.STATUS_COMPLETED]: "Completed",
};

// UI text for Landing status
export const LANDING_STATUS_TEXT = {
  [constants.LANDING_STATUS_NOT_START]: "Not Started",
  [constants.LANDING_STATUS_PROCESS]: "In Progress",
  [constants.LANDING_STATUS_ARCHIVED]: "Archived",
};

export const UPDATE_TESTCASE = {
  UNPUBLISHED: "unpublished",
  PUBLISHED: "published",
  REJECTED: "rejected",
};

export const METRIC_INFO = {
  INITIATIVE_RF: "ComplianceRF",
  INITIATIVE_EMI: "ComplianceEMI",
  TEST_CATEGORY: "RF",
  LANDING: "Landing Page",
  META_DATA: "Meta Data",
  BUID_GRAPH_DATA: "Build Graph Data",
  OVERVIEW: "Project Overview",
  TESTCASE: "TestCases",
  DATA: "Testing Data Visualization",
  REPORT: "Report",
  MANUAL_UPLOAD: "Manual Upload",
  DOWNLOAD_TEST_DATA: "Download Test Data",
  CATEGORY: "Categories",
  PROJECT: "Projects",
  ACE: "ACE",
  BUILD: "Build Stage",
  CREATE_CATEGORY: "Create Category",
  CREATE_PROJECT: "Create Project",
  CREATE_BUILD: "Create Build",
  CREATE_RF_CONFIG: "Create RF Config",
  CREATE_TESTPLAN: "Create Testplan",
  TESTPLAN_EMI: "TestPlans",
  CREATE_TESTPLAN_DRAFT: "Create Testplan Draft",
  SEND_MAIL_TESTPLAN: "Send Mail Test Plan",
  METRIC: "Metric",
  AUTHORIZATION: "Authorization",
  FILTER_PREFERENCES: "Filter",
  EXPORT: "Export",
  UPDATE_TESTCASE: "Update Testcase",
  VISUALIZATION: "Visualization",
};

export const METRICS_STATUS = {
  PASS: "PASS",
  FAIL: "FAIL",
};

export const COMPONENT_ID = {
  CATEGORY: "category",
  COMMENT: "comment",
  PROJECT: "project",
  TEST_CATEGORY: "test_category",
  BUILD: "build",
  VERSION: "version",
  START_DATE: "start_date",
  END_DATE: "end_date",
  SELECTED_BUILD: "selected_build",
  STREET_DATE: "street_date",
  MODEL_NUMBER: "model_number",
  PVT_RR: "pvt_rr",
  ANNOUNCE_DATE: "announce_date",
  DEVICE_CATEGORY: "device_category",
};

export const DROPDOWN_DEFAULT = {
  CATEGORY: "Category",
  PROJECT: "Project",
  BUILD: "Build",
  VERSION: "Version",
  BUILD_CONFIG: "Build Config",
  REGION: "Region",
  TECHNOLOGY: "Technology",
  ANTENNA: "Antenna",
  SPECIFICATION: "Specification",
  LAB: "Lab",
  ID: 0,
};

export const FILE_TYPE = {
  RF: "RF",
  EMI: "EMI",
  RF_TESTPLAN: "rf_testplan",
  RF_TESTDATA: "rf_testdata",
  EMI_TESTPLAN: "emi_testplan",
  EMI_TESTDATA: "emi_testdata",
};

export const FILE_NAME_FORMAT = {
  [FILE_TYPE.RF_TESTPLAN]: {
    text: "RF Testplan",
    format: "<:category>_<:project>_<:build>_<:version>_<:build_config>.xlsm",
    example: "aucc_laser_hvt_1.0_a1d1.xlsm",
  },
  [FILE_TYPE.RF_TESTDATA]: {
    text: "RF Testdata",
    format:
      "<:id>_<:category>_<:project>_<:build>_<:version>_<:build_config>.zip",
    example: "2901_aucc_laser_hvt_1.0_a1d1.zip",
  },
  [FILE_TYPE.EMI_TESTPLAN]: {
    text: "EMI Testplan",
    format: "<:category>_<:project>_<:build>_<:version>.xlsm",
    example: "aucc_laser_hvt_1.0.xlsm",
  },
  [FILE_TYPE.EMI_TESTDATA]: {
    text: "EMI Testdata",
    format:
      "<:id>_<:category>_<:project>_<:build>_<:version>_<:config>_<:section>.zip",
    example:
      "2901_aucc_laser_hvt_1.0_HAP0_FCC 15B Radiated Emissions (+1GHz).zip",
  },
};
export const TEST_CASE_STATUS = ["failed", "passed", "untested"];

export const DATA_VISUALIZATION_INSTRUCTION = [
  "The Testcase Configuration table shows the configuration of the testcase.",
  "The Testcase Final Power table shows the data for final power setting of the testcase",
  "The Testcase Summay table shows the summary data for all power settings of the testcase.",
  "Graphs for the raw data can be visualized by clicking on the colored fields in the Testcase Summary table.",
  "The fields written in black do not have any raw data.",
];

export const DATA_VISUALIZATION_LEGEND = {
  RED: "The calculated data (from raw data shared by lab) does not match with the lab calculations (values in test plan shared by lab).",
  GREEN:
    "The calculated data (from raw data shared by lab) match with the lab calculations (values in test plan shared by lab).",
  BLUE: "The lab has only shared raw data files (Testplan not yet uploaded by lab).",
};

// Report generation
export const REPORT_TYPE = {
  TESTCASE_REPORT: "testcase report",
};

export const REPORT_TYPE_TEXT = {
  [REPORT_TYPE.TESTCASE_REPORT]: "Testcase Report",
};

export const IFRAME = {
  QUERY_STRING: "?inIframe=true",
};

export const LAB = {
  SPORTON: "SPROTNLAB",
  TUV: "TUVR",
  UL: "ULLAB",
};

export const deviceCategories = ["V0", "V1", "V2"];

export const JIRA_SECURITY_LEVELS = [
  "Amazon",
  "Amazon + Ensky",
  "Amazon + Cnt",
  "Amazon + Tonly",
  "Amazon + Goertek",
  "Amazon + tcl",
  "Amazon + Huaqin"
]

export const CYCLE_DUTY_VALUES = [
  "6XD",
  "6CD",
]

export const EIRP_PSD_VALUES = [
  "LPI",
  "VLP",
]

export enum Status {
  Pending = "pending",
  InProgress = "in_progress",
  Success = "success",
  Failed = "failed",
}

export enum TEXT_TYPE {
  Paragraph = "PARAGRAPH",
  Header1 = "HEADER_1",
  Header2 = "HEADER_2",
  Header3 = "HEADER_3",
}

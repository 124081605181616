const uploadType = {
  // get metadata
  GET_UPLOAD: "GET_UPLOAD",
  GET_UPLOAD_COMMIT: "GET_UPLOAD_COMMIT",
  GET_UPLOAD_ROLLBACK: "GET_UPLOAD_ROLLBACK",

  CREATE_UPLOAD: "CREATE_UPLOAD",
  CREATE_UPLOAD_COMMIT: "CREATE_UPLOAD_COMMIT",
  CREATE_UPLOAD_ROLLBACK: "CREATE_UPLOAD_ROLLBACK",

  TRIGGER_AUTO_UPLOAD: "TRIGGER_AUTO_UPLOAD",
  TRIGGER_AUTO_UPLOAD_COMMIT: "TRIGGER_AUTO_UPLOAD_COMMIT",
  TRIGGER_AUTO_UPLOAD_ROLLBACK: "TRIGGER_AUTO_UPLOAD_ROLLBACK",

  RESET_DOWNLOAD: "RESET_DOWNLOAD_PRESIGNED_URL",
  GET_DOWNLOAD: "GET_DOWNLOAD_PRESIGNED_URL",
  GET_DOWNLOAD_COMMIT: "GET_DOWNLOAD_PRESIGNED_URL_COMMIT",
  GET_DOWNLOAD_ROLLBACK: "GET_DOWNLOAD_PRESIGNED_URL_ROLLBACK",
};

export default uploadType;

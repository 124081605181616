import {
  ColumnLayout,
  FormField,
  Multiselect,
  Select,
  StatusIndicator,
  SelectProps,
} from "@amzn/awsui-components-react-v3";
import React, {useEffect} from "react";

export function AceSectionSelector(
  {
    sectionId,
    selectedFileMap,
    sectionStructureMap,
    selectedSectionOptionsMap,
    selectedPageNumbersMap,
    handlePageSelect,
    uploadedFileOptions,
    handleFileSelect,
    loadingSections,
    handleSectionsSelect
  }
) {
  const selectedFile = selectedFileMap[sectionId] || "";
  const selectedFileOption = selectedFile
    ? {label: selectedFile, value: selectedFile}
    : null;
  const sectionOptions = sectionStructureMap?.[selectedFile]?.sectionOptions || [];
  const selectedSectionOptions =
    selectedSectionOptionsMap[sectionId]?.[selectedFile] || [];

  const isSelectedFilePdf = selectedFile.toLowerCase().endsWith('.pdf');
  const totalPages = sectionStructureMap?.[selectedFile]?.totalPages;
  const pageOptions: SelectProps.Option[] = [];
  if (totalPages) {
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      pageOptions.push({label: pageNumber.toString(), value: pageNumber.toString()});
    }
  }

  const selectedPageNumbers = selectedPageNumbersMap?.[sectionId]?.[selectedFile] || []

  useEffect(() => {
    if (selectedFile) {
      handlePageSelect([], sectionId, selectedFile)
    }
  }, [selectedFile]);

  return (
    <ColumnLayout columns={4}>
      <FormField label="Document">
        <Select
          options={uploadedFileOptions}
          onChange={(event) => handleFileSelect(sectionId, event)}
          selectedOption={selectedFileOption}
          placeholder="Choose a file"
          disabled={loadingSections}
        />
      </FormField>
      {selectedFile && (
        <FormField label="Sections">
          {!sectionOptions ? (
            <StatusIndicator type="loading">
              Loading ...
            </StatusIndicator>
          ) : (
            <Multiselect
              options={sectionOptions}
              onChange={(e) =>
                handleSectionsSelect(sectionId, selectedFile, e)
              }
              selectedOptions={selectedSectionOptions}
              placeholder="Select section"
            />
          )}
        </FormField>
      )}
      {selectedFile && isSelectedFilePdf && (
        <FormField label="Pages">
          {!totalPages ? (
            <StatusIndicator type="loading">
              Loading ...
            </StatusIndicator>
          ) : (
            <Multiselect
              options={pageOptions}
              onChange={(event) =>
                handlePageSelect(event.detail.selectedOptions, sectionId, selectedFile)
              }
              selectedOptions={selectedPageNumbers}
              placeholder="Select page"
            />
          )}
        </FormField>
      )}
    </ColumnLayout>
  )
}
